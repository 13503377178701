<template>
  <div>
    <v-form>
      <p class="mb-3">
        In the acid-base extraction lab, a carboxylic acid was separated from fluorene by extracting
        the carboxylic acid into the aqueous layer using
        <chemical-latex content="NaOH" />
        solution (a review of this experiment before lab will be helpful). Phenols (<i
          >aromatic alcohols</i
        >) can also be separated using this method. In a mixture of a phenol and a non-reacting
        organic compound, the phenol may be selectively removed from an organic solvent by
        extracting with a dilute
        <chemical-latex content="NaOH" />
        solution. After separating the organic and aqueous layers, the phenol may be recovered from
        the aqueous solution by adding
        <chemical-latex content="HCl\text{.}" />
        The recovered phenol will either precipitate from the aqueous solution, or it can be
        extracted into an organic solvent using a separatory funnel. Answer the given questions
        about this procedure.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LB_A8Q15',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
};
</script>
